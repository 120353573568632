type Disposer = () => void;
type Debounce = (
  fn: Function,
  delay: number
) => [(...args: any[]) => void, Disposer];

// todo: add types
export const debounce: Debounce = function (fn: Function, delay: number) {
  let timeoutID: NodeJS.Timeout | null = null;

  return [
    (...args: any) => {
      timeoutID && clearTimeout(timeoutID);
      timeoutID = setTimeout(() => {
        // @ts-ignore
        fn.apply(this, args);
      }, delay);
    },
    // @ts-ignore
    () => clearTimeout(timeoutID),
  ];
};

export const sleep = (ms: number) => new Promise((r) => setTimeout(r, ms));
