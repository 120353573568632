import { Amplify, Auth } from 'aws-amplify';

import awsConfig from '@/aws-exports';

Amplify.configure({
  ...awsConfig,
  ssr: false,
});

export { Auth, Amplify };
