import { isNonEmptyNumber } from './predicates';

const units = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];

export function formatBytes(a: number, b = 2, k = 1024) {
  let d = Math.floor(Math.log(a) / Math.log(k));
  if (0 === a) {
    return '0B'
  } else {
    return  `${parseFloat((a / Math.pow(k, d)).toFixed(Math.max(0, b)))}  ${units[d]}`;
  }
}

export function formatCurrency(rawValue: number | string): string {
  let value = 0;
  if (typeof rawValue === 'string') {
    const parsedValue = parseInt(rawValue, 10);
    if (!Number.isNaN(parsedValue)) {
      value = parsedValue;
    }
  }

  if (!isNonEmptyNumber(value)) {
    return ``;
  }

  try {
    return new Intl.NumberFormat(
      'cs-CS',
      {
        style: 'currency',
        currency: 'CZK',
      }
    ).format(value);
  } catch (error) {
    console.error(error);
    return `-`;
  }
}
