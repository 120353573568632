import { ReactNode, useEffect, useState } from 'react';

import { Radio, RadioGroup, Wrap, WrapItem } from '@chakra-ui/react';
import { FieldProps, useField } from '@formiz/core';
import { useTranslation } from 'react-i18next';

import { FormGroup, FormGroupProps } from '@/components/FormGroup';

type Option = {
  value: string | number | undefined;
  label?: ReactNode;
};

export type FieldRadiosProps = FieldProps &
  FormGroupProps & {
    size?: 'sm' | 'md' | 'lg';
    options?: Option[];
    direction?: 'row' | 'column',
  } & {
    i18nPrefix?: string; // todo: refactor as general interface
  };

export const FieldRadios = (props: FieldRadiosProps) => {
  const { t } = useTranslation();
  const {
    errorMessage,
    id,
    isValid,
    isSubmitted,
    resetKey,
    setValue,
    value,
    otherProps,
  } = useField(props);
  const { required } = props;
  const {
    children,
    label,
    options = [],
    helper,
    size = 'md',
    i18nPrefix = '',
    direction = 'row',
    ...rest
  } = otherProps as Omit<FieldRadiosProps, keyof FieldProps>;
  const [isTouched, setIsTouched] = useState(false);
  const showError = !isValid && (isTouched || isSubmitted);

  useEffect(() => {
    setIsTouched(false);
  }, [resetKey]);

  const formGroupProps = {
    errorMessage,
    helper,
    id,
    isRequired: !!required,
    label,
    showError,
    ...rest,
  };

  const handleSetValue = (value: string | number) => {
    setValue(typeof value === 'string' && `${parseInt(value, 10)}` === value ? parseInt(value, 10) : value);
  }

  return (
    <FormGroup {...formGroupProps}>
      <RadioGroup size={size} id={id} value={value} onChange={handleSetValue}>
        <Wrap spacing="4" pl={1}>
          {options.map((option) => (
            <WrapItem
              key={option.value}
              w={direction === 'column' ? 'full' : 'initial'}
            >
              <Radio
                id={`${id}-${option.value}`}
                name={id}
                value={`${option.value}`}
              >
              {typeof option.label === 'string' || typeof option.label === 'number' ?
                 t<string>(i18nPrefix + option.label) : option.label ?? JSON.stringify(option.value)}
              </Radio>
            </WrapItem>
          ))}
        </Wrap>
      </RadioGroup>
      {children}
    </FormGroup>
  );
};
