/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
  "aws_project_region": "eu-central-1",
  "aws_cognito_identity_pool_id": "eu-central-1:67f45cff-3b0c-4249-9c3f-d1cc671afca7",
  "aws_cognito_region": "eu-central-1",
  "aws_user_pools_id": "eu-central-1_UPOBqyxjA",
  "aws_user_pools_web_client_id": "1p4h9var4votindc4ntllkmttr",
  "oauth": {},
  "aws_cognito_username_attributes": [
    "EMAIL"
  ],
  "aws_cognito_social_providers": [],
  "aws_cognito_signup_attributes": [
    "EMAIL"
  ],
  "aws_cognito_mfa_configuration": "OFF",
  "aws_cognito_mfa_types": [
    "SMS"
  ],
  "aws_cognito_password_protection_settings": {
    "passwordPolicyMinLength": 8,
    "passwordPolicyCharacters": []
  },
  "aws_cognito_verification_mechanisms": [
    "EMAIL"
  ],
  "aws_user_files_s3_bucket": "hub642f8e5b0e674ffc904600e47219aa3e130720-prod",
  "aws_user_files_s3_bucket_region": "eu-central-1",
  "aws_content_delivery_bucket": "hub-20220824134146-hostingbucket-prod",
  "aws_content_delivery_bucket_region": "eu-central-1",
  "aws_content_delivery_url": "https://d39m0koz906nbc.cloudfront.net"
};

export default awsmobile;
