import Axios from 'axios';
import { UseMutationOptions, useMutation, UseQueryOptions, useQuery } from '@tanstack/react-query';
import { Auth } from '@/lib/amplify';
import { useAuthContext } from '@/app/auth/AuthContext';
import { ICredentials } from '@aws-amplify/core';

import { sleep } from '@/lib/utils';

import type { CognitoUser } from '@aws-amplify/auth';
import type { Account } from '@/app/account/account.types';
import type { AppError } from '@/errors/AppError';

export type Credentials = ICredentials;
export type SignInResult = CognitoUser & { attributes: Record<string, boolean> };
export type FormCredentials = {
  email: string;
  password: string;
  remeberDevice?: boolean;
  code?: string;
};

const authKeys = {
  all: () => ['authService'] as const,
  credentials: () => [...authKeys.all(), 'credentials'] as const,
};

export const useCredentials = (
  config: UseQueryOptions<
    Credentials,
    AppError,
    Credentials,
    InferQueryKey<typeof authKeys.credentials>
  > = {}
) => {
  const { data: credentials } = useQuery(
    authKeys.credentials(),
    async (): Promise<Credentials> => {
      return await Auth.currentUserCredentials();
    },
    {
      onSuccess: (data) => {
        if (config?.onSuccess) {
          config?.onSuccess(data);
        }
      },
      staleTime: Infinity,
      cacheTime: Infinity,
      refetchOnMount: false,
      refetchOnWindowFocus: false,
      ...config,
    }
  );

  return { credentials, foo: 'bar' };
};

export const useChangePassword = (
  _config: UseMutationOptions<
    {
      password: string,
    },
  Error,
  {
    password: string;
  }
  > = {}
) => {
  return useMutation(
    async () => {
      return {};
    }
  );
};

export type LoginData = {
  isCompleted: boolean;
  isAdmin: boolean;
  account: any;
  authToken: string | null;
};

export const useLogin = (
  config: UseMutationOptions<
    LoginData,
    AppError,
    FormCredentials & LoginData
  > = {}
) => {
  const { updateToken } = useAuthContext();

  return useMutation(
    async ({ email, password, remeberDevice = false, code = null }): Promise<LoginData> => {
      let signInResult: SignInResult;

      if (code) {
        console.log('=== CONFIRM CODE')
        const x = await Auth.confirmSignUp(email, code);
        console.log(x);
        await sleep(1000);
      }

      signInResult = await Auth.signIn(email, password);

      console.log('==== SIGN IN RESULT');
      console.log(signInResult);

      if (!signInResult.attributes.email_verified) {
        console.log('==== THROW USER NOT CONFIRMED ERROR');
        throw new Error('UserNotConfirmedException');
      }

      const idToken: string | null = signInResult?.getSignInUserSession()?.getIdToken().getJwtToken() ?? null;

      if (remeberDevice) {
        try {
          await Auth.rememberDevice();
        } catch (error) {
          throw new Error('AuthRememberDeviceException');
        }
      }

      updateToken(idToken);

      const account = await Axios.get<Pick<Account, 'authorities' | 'completed'>>('/account');

      console.log('==== ACCOUNT');
      console.log(account);

      const authorities = account?.authorities ?? [];
      const isCompleted = !!account?.completed;
      const isAdmin = authorities.includes('ADMIN');

      return {
        isCompleted,
        isAdmin,
        account,
        authToken: idToken,
      };
    },
    {
      ...config,
      onSuccess: (data, ...rest) => {
        updateToken(data.authToken);
        config.onSuccess?.(data, ...rest);
      },
      onError: (error, ...rest) => {
        config.onError?.(error, ...rest);
      },
    }
  );
};
