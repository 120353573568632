import React, { useEffect, useState } from 'react';
import dayjs from 'dayjs';
import {
  IconButton,
  Input,
  InputGroup,
  InputLeftElement,
  InputProps,
  InputRightElement,
  Spinner,
} from '@chakra-ui/react';
import { FieldProps, useField } from '@formiz/core';
import { RiEyeCloseLine, RiEyeLine } from 'react-icons/ri';
import { FiDelete } from 'react-icons/fi';

import { FormGroup, FormGroupProps } from '@/components/FormGroup';
import {
  DateSelector,
  DateSelectorPreviousDayButton,
  DateSelectorNextDayButton,
  DateSelectorPicker,
} from '@/components/DateSelector';

export type FieldDateProps = FieldProps &
  Omit<FormGroupProps, 'placeholder'> &
  Pick<InputProps, 'type' | 'placeholder'> & {
    size?: 'sm' | 'md' | 'lg';
    autoFocus?: boolean;
    autocomplete?: string;
  };

export const FieldDate = (props: FieldDateProps) => {
  const {
    errorMessage,
    id,
    isValid,
    isSubmitted,
    isValidating,
    resetKey,
    setValue,
    value,
    otherProps,
  } = useField(props);
  const {
    autocomplete, // eslint-disable-line @typescript-eslint/no-unused-vars
    children,
    label,
    type,
    placeholder, // eslint-disable-line @typescript-eslint/no-unused-vars
    helper,
    size = 'md',
    autoFocus, // eslint-disable-line @typescript-eslint/no-unused-vars
    ...rest
  } = otherProps as Omit<FieldDateProps, keyof FieldProps>;
  const { required } = props;
  const [isTouched, setIsTouched] = useState(false);
  const [showPassword, setShowPassword] = useState(false);

  const dateValue = dayjs(value);
  const isDateValid = dateValue.isValid();
  const dateValueOrNull = isDateValid ? dateValue : null;
  const showError = !isValid && (isTouched || isSubmitted);

  const setSelectedDate = (newValue: dayjs.Dayjs) => {
    setIsTouched(true);
    if (newValue.isValid()) {
      setValue(+newValue);
    }
  };

  const clearValue = () => {
    setValue(null);
    setIsTouched(true);
  };

  useEffect(() => {
    setIsTouched(false);
  }, [resetKey]);

  const formGroupProps = {
    errorMessage,
    helper,
    id,
    isRequired: !!required,
    label,
    showError,
    ...rest,
  };

  return (
    <FormGroup {...formGroupProps}>
      <InputGroup size={size} alignItems="center">
        <Input
          type={'hidden'}
          id={id}
          value={value ?? ''}
        />

        {type === 'password' && (
          <InputLeftElement>
            <IconButton
              onClick={() => setShowPassword((x) => !x)}
              aria-label={showPassword ? 'Hide password' : 'Show password'}
              display="flex"
              size="xs"
              fontSize="lg"
              icon={showPassword ? <RiEyeLine /> : <RiEyeCloseLine />}
              variant="unstyled"
            />
          </InputLeftElement>
        )}

        {(isTouched || isSubmitted) && isValidating && (
          <InputRightElement>
            <Spinner size="sm" flex="none" />
          </InputRightElement>
        )}
        <DateSelector
          onChange={setSelectedDate}
          date={dateValueOrNull}
        >
            <DateSelectorPreviousDayButton aria-label="Previous day" />
            <DateSelectorPicker />
            <DateSelectorNextDayButton aria-label="Next day" />
        </DateSelector>
        <IconButton
          ml={2}
          onClick={clearValue}
          aria-label={'Smazat tento datum'}
          display="none"
          size="xs"
          fontSize="lg"
          icon={<FiDelete />}
          variant="unstyled"
        />
      </InputGroup>
      {children}
    </FormGroup>
  );
};
