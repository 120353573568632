import { useEffect, useRef, useState } from 'react';
import {
  Box,
  Button,
  Icon,
  Input,
  InputGroup,
  InputProps,
  Image,
  HStack,
} from '@chakra-ui/react';
import { FieldProps, useField } from '@formiz/core';
import { FiFile } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import Compressor from 'compressorjs';

import { FiTrash2 } from 'react-icons/fi';
import { FormGroup, FormGroupProps } from '@/components/FormGroup';
import { useToastError, useToastSuccess } from '@/components';
import { Dropzone } from '../Dropzone';

export interface FieldFileProps
  extends FieldProps,
    Omit<FormGroupProps, 'placeholder'>,
    Pick<InputProps, 'type' | 'placeholder'> {
  size?: 'sm' | 'md' | 'lg';
}

export const FieldImageFile = (props: FieldFileProps) => {
  const { t } = useTranslation();
  const {
    errorMessage,
    id,
    isValid,
    isSubmitted,
    /* isValidating, */
    resetKey,
    setValue,
    value,
    otherProps,
  } = useField(props);
  const {
    children,
    label,
    // type,
    placeholder,
    helper,
    size = 'md',
    // ref,
    ...rest
  } = otherProps;
  const { required } = props;
  const [isTouched, setIsTouched] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const toastError = useToastError();
  const toastSuccess = useToastSuccess(); // eslint-disable-line @typescript-eslint/no-unused-vars

  const showError = !isValid && (isTouched || isSubmitted);

  useEffect(() => {
    setIsTouched(false);
  }, [resetKey]);

  const formGroupProps = {
    errorMessage,
    helper,
    id,
    isRequired: !!required,
    label,
    showError,
    ...rest,
  };

  const handleTriggerInputBox = () => {
    inputRef.current?.click();
  };

  const convertToBase64 = (file: File | Blob) => {
    return new Promise((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);
      fileReader.onload = () => {
        resolve(fileReader.result);
      };
      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleChange = async (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
    if (event.target.files === null || event.target.files.length < 1) {
      return;
    }
    const file = event.target.files[0];
    await onFileAccepted(file);
  };

  const onFileAccepted = async (file: File) => {
    new Compressor(file, {
        quality: 0.6,
        maxWidth: 600,
        convertTypes: 'image/webp',
        async success(file) {
          const base64 = await convertToBase64(file);
          setValue(base64);
        },
        error(error) {
          toastError({
              title: t<string>(`forms:common.generalError`),
              description: t<string>(`forms:fields.imageFile.cannotAttach`)
          });
          console.info(error);
        },
    });
  };

  const handleClearValue = () => setValue(null);

  console.log('VALUE:', value);

  return (
    <>
      <Dropzone onFileAccepted={onFileAccepted} />
      {value && <Image src={value} w={150} />}
      <FormGroup {...formGroupProps}>
        <HStack>
        <InputGroup size={size}>
          <Input
            type="file"
            id={id}
            multiple={false}
            accept="image/*"
            onBlur={() => setIsTouched(true)}
            placeholder={placeholder ? String(placeholder) : ''}
            onChange={handleChange}
            ref={inputRef}
            style={{ display: 'none' }}
          />
          <Button
            onClick={() => handleTriggerInputBox()}
            ml={0}
            variant="@secondary"
          >
            <Icon as={FiFile} mr={2} /> {t<string>(`forms:fields.fieldImage.${value ? 'changeImage': 'addImage'}`)}
          </Button>
        </InputGroup>
        <InputGroup>
        <Button
          onClick={() => handleClearValue()}
          ml={0}
          variant="@secondary"
        >
          <Icon as={FiTrash2} mr={2} /> {t<string>(`forms:fields.fieldImage.removeImage`)}
        </Button>
        </InputGroup>
        </HStack>
        <Box>
          {children}
        </Box>
      </FormGroup>
    </>
  );
};
