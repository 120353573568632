import { Button, IconButton } from '@chakra-ui/react';
import { FiBold, FiItalic } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';

export const MenuBar = ({ editor }: any) => {
  const { t } = useTranslation();

  if (!editor) {
    return null;
  }

  return (
    <>
      <IconButton
        onClick={() => editor.chain().focus().toggleBold().run()}
        isActive={editor.isActive('bold')}
        title="bold"
        aria-label="bold"
        icon={<FiBold />}
      />
      <IconButton
        onClick={() => editor.chain().focus().toggleItalic().run()}
        isActive={editor.isActive('bold')}
        aria-label="italic"
        icon={<FiItalic />}
      />
      <Button
        onClick={() => editor.chain().focus().toggleHeading({ level: 2 }).run()}
        isActive={editor.isActive('heading', { level: 2 })}
      >
        {t<string>('forms:fields.textEditor.heading')}
      </Button>
      <Button
        onClick={() => editor.chain().focus().toggleHeading({ level: 3 }).run()}
        isActive={editor.isActive('heading', { level: 2 })}
      >
        {t<string>('forms:fields.textEditor.subheading')}
      </Button>
    </>
  );
};
