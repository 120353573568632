import { FC, ReactNode } from 'react';
import {
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Popover,
  PopoverBody,
  PopoverContent,
  PopoverTrigger,
  chakra,
  useBreakpointValue,
} from '@chakra-ui/react';
import dayjs, { Dayjs } from 'dayjs';
import { DayPicker, DayPickerProps } from 'react-day-picker';
import ReactFocusLock from 'react-focus-lock';
import cs from 'date-fns/locale/cs';

import { useDateSelectorContext } from './DateSelector';

type ChildrenFunctionParams = { date: Dayjs; onOpen: () => void };
type DateSelectorPickerProps = Omit<DayPickerProps, 'children' | 'mode'> & {
  children?: ({ date, onOpen }: ChildrenFunctionParams) => ReactNode;
};

const defaultChildren = ({ date, onOpen }: ChildrenFunctionParams) => (
  <chakra.button onClick={onOpen} px="2" type="button">
    {date !== null ? date.format('DD MMM YYYY') : '???'}
  </chakra.button>
);

export const DateSelectorPicker: FC<DateSelectorPickerProps> = ({
  children = defaultChildren,
  ...rest
}) => {
  const isMobile = useBreakpointValue({ base: true, md: false });

  const { date, onDayClick, isOpen, onOpen, onClose } =
    useDateSelectorContext();

  const defaultMonth = date !== null ? date.toDate() : dayjs().toDate();
  const selected = date !== null ? [date.toDate()] : undefined;

  const dayPicker = (
    <DayPicker
      locale={cs}
      defaultMonth={defaultMonth}
      selected={selected as any}
      onDayClick={(d) => onDayClick(dayjs(d))}
      mode='single'
      {...rest}
    />
  );

  if (isMobile) {
    return (
      <>
        {date ? children({ date, onOpen }) : 'TODO' }
        <Modal isOpen={isOpen} onClose={onClose} size="xs">
          <ModalOverlay />
          <ModalContent>
            <ModalBody>{dayPicker}</ModalBody>
          </ModalContent>
        </Modal>
      </>
    );
  }

  return (
    <Popover isOpen={isOpen} onOpen={onOpen} onClose={onClose}>
      <PopoverTrigger>{date ? children({ date, onOpen }) : 'TODO'}</PopoverTrigger>
      <PopoverContent
        minW="0"
        p="0"
        my="2"
        border="none"
        boxShadow="none"
        width="min-content"
      >
        <ReactFocusLock>
          <PopoverBody
            p="0"
            border="1px solid"
            borderRadius="md"
            borderColor="gray.200"
            _dark={{ borderColor: 'gray.900' }}
          >
            {dayPicker}
          </PopoverBody>
        </ReactFocusLock>
      </PopoverContent>
    </Popover>
  );
};
