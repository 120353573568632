import type { AppError } from '@/errors/AppError';

export function isNonEmptyNumber(value: unknown): value is number {
  return typeof value === 'number' && value > 0;
}

export function isString(value: unknown): value is string {
  return typeof value === 'string';
}

export function isMbString(value: unknown): value is string | null {
  return typeof value === 'string' || value === null;
}

export function isNonEmptyString(value: unknown): value is string {
  return isString(value) && value.length > 0;
}

export function isEmptyString(value: unknown): value is string {
  return isString(value) && value.length === 0;
}

export function isNonEmptyArray<T=any>(value: unknown): value is T[] {
  return Array.isArray(value) && value.length > 0;
}

export function isEmptyArray<T=any>(value: unknown): value is T[] {
  return Array.isArray(value) && value.length === 0;
}

export function isArray<T=any>(value: unknown): value is T[] {
  return Array.isArray(value);
}

export function isNonEmpty(value: unknown): value is null | undefined {
  return typeof value !== 'undefined' && value !== null && !isEmptyArray(value);
}

export function isEmpty(value: unknown): value is null | undefined {
  return typeof value === 'undefined' || value === null || isEmptyArray(value) || isEmptyString(value);
}

export function isNotEmpty(value: unknown): boolean {
  return !isEmpty(value);
}

export function isAppError(value: unknown): value is AppError {
  return value instanceof Error && isNonEmptyString((value as AppError).code);
}

export function isObject(value: unknown): value is Object {
  return typeof value === 'object' && value !== null;
}
