import { useCallback } from 'react';
import { useDropzone } from 'react-dropzone';
import { Center, useColorModeValue, Icon } from '@chakra-ui/react';
import { useTranslation } from 'react-i18next';
import { FiFile } from 'react-icons/fi';

export type DropzoneProps = {
  onFileAccepted: (file: File) => void,
  accept?: string[],
};

export function Dropzone({ onFileAccepted, accept }: DropzoneProps) {
  const { t } = useTranslation();
  const onDrop = useCallback((acceptedFiles: File[]) => {
    onFileAccepted(acceptedFiles[0]);
  }, [onFileAccepted]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    accept: {
      'image/*': accept ?? ['.jpeg', '.jpg', '.png', '.webp']
    },
    maxFiles: 1,
    multiple: false,
  });

  const dropText = isDragActive ? t('forms:fields.dd.activeMessage') : t('forms:fields.dd.inactiveMessage');

  const activeBg = useColorModeValue('gray.100', 'gray.600');
  const borderColor = useColorModeValue(
    isDragActive ? 'teal.300' : 'gray.300',
    isDragActive ? 'teal.500' : 'gray.500',
  );

  return (
    <Center
      p={10}
      cursor="pointer"
      bg={isDragActive ? activeBg : 'transparent'}
      _hover={{ bg: activeBg }}
      transition="background-color 0.2s ease"
      borderRadius={4}
      border="3px dashed"
      borderColor={borderColor}
      {...getRootProps()}
    >
      <input {...getInputProps()} />
      <Icon as={FiFile} mr={2} />
      <p>{dropText}</p>
    </Center>
  );
}

export default Dropzone;
