import { useEffect, useRef, useState } from 'react';
import Axios from 'axios';
import {
  Box,
  Button,
  Icon,
  Input,
  InputGroup,
  InputProps,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Flex,
  Divider,
  HStack,
} from '@chakra-ui/react';
import { FieldProps, useField } from '@formiz/core';
import { FiFile, FiTrash2, FiDownload, FiMoreVertical } from 'react-icons/fi';
import { useTranslation } from 'react-i18next';
import { v4 as uuid } from 'uuid';

import { useCredentials } from '@/app/auth/auth.service';
import { Icon as IconComponent, ConfirmMenuItem, useToastError, useToastSuccess } from '@/components';
import { FormGroup, FormGroupProps } from '@/components/FormGroup';
import { formatBytes } from '@/lib/format';
import { Storage } from 'aws-amplify';
import { absurd } from '@/lib/assert';

import type { NewAsset } from '@/domain/asset';
import type { AssetDocument } from '@/models/asset';
import type { FC } from 'react';
import type { PutResult } from '@aws-amplify/storage/lib-esm/types';

const DEFAULT_ACCEPT_FILES = '*';

function isNonEmptyNumber(value: unknown): value is number {
  return typeof value === 'number' && value > 0;
}

function humanFileSize(size: number): string | null {
  if (!isNonEmptyNumber(size)) {
    return null;
  }
  const i = Math.floor(Math.log(size) / Math.log(1024));
  const value = +(size / Math.pow(1024, i)).toFixed(2) * 1;
  const unit = ['B', 'kB', 'MB', 'GB', 'TB'][i];

  return `${value} ${unit}`;
}

interface ProgressEvent extends Event {
  total: number;
  loaded: number;
}

export interface FieldS3FileProps
  extends FieldProps,
    Omit<FormGroupProps, 'placeholder'>,
    Pick<InputProps, 'type' | 'placeholder'> {
  size?: 'sm' | 'md' | 'lg';
  acceptedFileTypes?: string;
}

type IndexedFile = File & { id: string };

type UploadItem = {
  label: string;
  labelTag: string;
  description: string;
  icon: string;
  id: string;
};

type HistoryItem = {
  id: string;
  percentage: number;
  filename: string;
  filetype: string;
  filesize: string;
  status: 'in-progress' | 'success' | 'failure';
};

const HistoryItemRow: FC<{ item: HistoryItem }> = ({ item: { id, status, filename, percentage } }) => {
  const { t } = useTranslation();

  return (
    <HStack borderBottom="1px dotted gray" key={id}>
      <Box flex={1}>
        {filename}
      </Box>
      <Box>
        {status !== 'in-progress' ? t<string>(`forms:fields.s3file.uploadStatus.${status}`) : `${percentage}%`}
      </Box>
    </HStack>
  );
};

export const FieldS3File = (props: FieldS3FileProps) => {
  const { t } = useTranslation();
  const {
    errorMessage,
    id,
    isValid,
    isSubmitted,
    resetKey,
    setValue,
    value,
    otherProps,
  } = useField(props);
  const {
    // children,
    label,
    helper,
    size = 'md',
    acceptedFileTypes,
    // ref,
    ...rest
  } = otherProps;
  const { required } = props;

  const toastSuccess = useToastSuccess();
  const toastError = useToastError();
  const inputRef = useRef<HTMLInputElement>(null);
  const [isTouched, setIsTouched] = useState<boolean>(false);
  const [_uploadList, setUploadList] = useState<UploadItem[]>([
  ]);
  const [_fileList, setFileList] = useState<(File & { id?: string })[]>([]);
  const [historyList, setHistoryList] = useState<HistoryItem[]>([]);
  const [historyCount, setHistoryCount] = useState<number>(0);
  const { credentials } = useCredentials();

  const showError = !isValid && (isTouched || isSubmitted);

  useEffect(() => {
    setIsTouched(false);
  }, [resetKey]);

  const formGroupProps = {
    errorMessage,
    helper,
    id,
    isRequired: !!required,
    label,
    showError,
    ...rest,
  };

  const removeAssetHandler = (itemId: string | null, itemIdx: number) => {
    const temp = [...value];
    temp.splice(itemIdx, 1);
    setValue(temp);

    if (itemId !== null) {
      Axios.delete(`/admin/assets/${itemId}`).then((response) => {
        console.info(response);
      });
    }
  };

  function progressBarFactory(id: string, fileList: IndexedFile[]) {
    const fileObject: File | undefined = fileList.find((file) => file.id === id);
    if (!fileObject) {
      throw new Error();
    }
    const newItem: HistoryItem = {
      id,
      percentage: 0,
      filename: fileObject.name,
      filetype: fileObject.type,
      filesize: formatBytes(fileObject.size),
      status: 'in-progress',
    };

    setHistoryList([...historyList, newItem]);

    return (progress: ProgressEvent) => {
      const percentage = Math.round((progress.loaded / progress.total) * 100);
      newItem.percentage = percentage;
      if (percentage === 100) {
        newItem.status = 'success';
      }
      setHistoryList([...historyList, newItem]);
    };
  }

  const setDescriptionHandler = (itemId: string, description: string) => {
    setValue((prevValue: NewAsset[]) => {
      const item = prevValue.find((i) => i.id === itemId);
      item && (item.description = description);

      return prevValue;
    });
  };

  const handleDownloadAsset = (asset: AssetDocument | NewAsset) => {
    Axios.post<TODO>(`/assets/${encodeURIComponent(asset.key)}`).then((response) => {
      if (!response.success) {
        return;
      }
      const element = document.createElement('a');
      element.href = response.downloadUrl;
      element.setAttribute('download', response.key);
      document.body.appendChild(element);
      element.click();
    });
  };

  const _handleUpload = async(uploadList: UploadItem[], fileList: IndexedFile[]) => {
    if (uploadList.length === 0) {
      toastError({
        title: t<string>(`forms:common.generalError`),
        description: t<string>(`forms:fields.s3file.emptyFiles`)
      });

      return;
    }
    setHistoryCount(historyCount + uploadList.length);
    const progressBar: ((progress: ProgressEvent) => void)[] = [];

    const promiseUploadAll: Promise<NewAsset>[] = uploadList.map(async (file) => {
      const { id } = file;
      const progressCallback = progressBarFactory(id, fileList);
      progressBar.push(progressCallback);

      const fileObject = fileList.find((x) => x.id === id);

      if (!fileObject) {
        throw new Error();
      }

      const S3Result: PutResult = await Storage.put(fileObject.name, fileObject, {
        progressCallback,
        level: "protected",
      });

      return {
        id: null,
        key: S3Result.key,
        identityId: credentials?.identityId || absurd(),
        filename: S3Result.key,
        mimetype: fileObject.type,
        size: +fileObject.size,
      };
    });

    try {
      const uploadResult = await Promise.all(promiseUploadAll);
      setValue((value || []).concat(uploadResult));
      toastSuccess({
          title: t<string>(`forms:common.information`),
          description: t<string>(`forms:fields.s3file.succesfullUpload`)
      });
    } catch (error) {
      setUploadList([]);
      toastError({
        title: t<string>(`forms:common.generalError`),
        description: t<string>(`forms:fields.s3file.uploadFailure`)
      });
    }
  };

  const handleFileInputChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    event.preventDefault();
      if (event.target.files === null || event.target.files.length < 1) {
      return;
    }

    const targetFiles: IndexedFile[] = Array.from(event.target.files)
      .map((file) => Object.assign(file, { id: uuid() }));

    const uploadList: UploadItem[] = targetFiles.map((file) => {
      return {
        label: file.name,
        labelTag: formatBytes(file.size),
        description: 'File type: ' + file.type,
        icon: 'file',
        id: file.id,
      };
    });
    setFileList(targetFiles);
    setUploadList(uploadList);
    _handleUpload(uploadList, targetFiles).then(() => console.log('done'));
  };

  const handleAddFileButtonClick = () => {
    if (inputRef.current === null) {
      return;
    }
    inputRef.current.value = ""; // This avoids errors when selecting the same files multiple times
    inputRef.current.click();
  };

  return (
    <>
      <FormGroup {...formGroupProps}>
        <Box mb={2}>
          {value ? (
            value.map((file: NewAsset | AssetDocument, idx: number) => (
              <Flex direction="column" className="list-group-item" key={`${file.id}_${idx}`}>
                <Flex w="full">
                  <Box flex="1">
                  {file.filename} - {humanFileSize(file.size)}
                  </Box>
                  <Menu>
                    <MenuButton as={Button} size="sm" id={'asset_menu' + idx}>
                      <IconComponent icon={FiMoreVertical} />
                    </MenuButton>
                    <MenuList>
                      <ConfirmMenuItem
                        icon={<IconComponent
                                icon={FiTrash2}
                                fontSize="lg" color="gray.400" />}
                        onClick={() => removeAssetHandler(file.id, idx)}
                      >
                        {t<string>('actions.delete')}
                      </ConfirmMenuItem>
                      {!!(file as AssetDocument)._id &&
                       (<MenuItem
                          icon={<IconComponent
                                  icon={FiDownload}
                                  fontSize="lg" color="gray.400"
                          />}
                          onClick={() => handleDownloadAsset(file)}
                        >
                         {t<string>('actions.download')}
                       </MenuItem>)
                      }
                    </MenuList>
                  </Menu>
                </Flex>
                <Box w="full" pr={8}>
                  <Input
                    ml={-3}
                    variant={'subField'}
                    size={'sm'}
                    type={'text'}
                    id={file.id + '__description'}
                    value={file.description ?? ''}
                    onChange={(e) => setDescriptionHandler(file.id, e.target.value)}
                    placeholder={'poznámka k příloze'}
                  />
                </Box>
                {value.length > 1 && (<Divider my={2} />)}
              </Flex>
            ))
          ) : (
            <Box></Box>
          )}
        </Box>
        <InputGroup size={size}>
          <Input
            type="file"
            id={id}
            multiple
            onBlur={() => setIsTouched(true)}
            onChange={handleFileInputChange}
            accept={acceptedFileTypes ?? DEFAULT_ACCEPT_FILES}
            ref={inputRef}
            style={{ display: 'none' }}
          />
          <Button
            onClick={() => handleAddFileButtonClick()}
            ml={0}
            variant="@secondary"
          >
            <Icon as={FiFile} mr={2} /> Nahrát přílohu
          </Button>
        </InputGroup>
        {historyList.length ? (<Box pt={2}>
          {historyList.map((item) => (<HistoryItemRow item={item} key={item.id} />))}
        </Box>) : null}
      </FormGroup>
    </>
  );
};
