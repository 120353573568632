import { useEffect, useState } from 'react';
import { Box } from '@chakra-ui/react';
import { useStyleConfig } from '@chakra-ui/system';
import { FieldProps, useField } from '@formiz/core';
import { EditorContent, useEditor } from '@tiptap/react';
import Document from '@tiptap/extension-document';
import Paragraph from '@tiptap/extension-paragraph';
import Bold from '@tiptap/extension-bold';
import Link from '@tiptap/extension-link';
import Heading from '@tiptap/extension-heading';
import Text from '@tiptap/extension-text';
import Italic from '@tiptap/extension-italic';
import ListItem from '@tiptap/extension-list-item';
import Image from '@tiptap/extension-image';
import OrderedList from '@tiptap/extension-ordered-list';

import { FormGroup, FormGroupProps } from '@/components';
import { MenuBar } from './MenuBar';

export interface FieldTextareaProps extends FieldProps, FormGroupProps {}

export const FieldTextEditor = (props: FieldTextareaProps) => {
  const {
    errorMessage,
    id,
    isValid,
    isSubmitted,
    resetKey,
    setValue,
    value,
    otherProps,
  } = useField(props);

  const styles = useStyleConfig('Textarea', props);

  const { helper, label, /* placeholder, textareaProps, */ ...rest } = otherProps;

  const { required } = props;
  const [isTouched, setIsTouched] = useState(false);

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [_isFocused, setFocused] = useState(false);

  const showError = !isValid && (isTouched || isSubmitted);

  const editor = useEditor({
    extensions: [
      Document,
      Paragraph,
      Text,
      Link,
      Bold,
      Heading,
      Italic,
      ListItem,
      Image,
      OrderedList,
    ],
    content: value ?? '',
    onUpdate({ editor }) {
      setValue(editor.getJSON());
    },
    onBlur() {
      setIsTouched(true);
      setFocused(false);
    },
    onFocus() {
      setFocused(true);
    },
    editorProps: {
      attributes: {
        class: 'field-text-editor',
        style: '',
      },
    },
  });

  useEffect(() => {
    setIsTouched(false);
  }, [resetKey]);

  const formGroupProps: FormGroupProps = {
    errorMessage,
    helper,
    id,
    isRequired: !!required,
    label,
    showError,
    ...rest,
  };

  return (
    <FormGroup {...formGroupProps}>
      <MenuBar editor={editor} />
      <Box
        height={'auto'}
        __css={styles}
        h="full"
      >
        <EditorContent editor={editor} />
      </Box>
    </FormGroup>
  );
};
